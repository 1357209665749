var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"m-4",attrs:{"title":((_vm.busId ? 'Edit' : 'Add') + " Bus"),"size":"lg","no-close-on-backdrop":"","scrollable":""},on:{"hidden":function($event){return _vm.$emit('modal-closed')},"ok":_vm.submit},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.saving || _vm.loading || _vm.loadingError ? true : false},on:{"click":function($event){return ok()}}},[_vm._v(" Submit "),(_vm.saving)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):_vm._e()],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Small Spinner","variant":"primary"}})],1):_vm._e(),_c('b-alert',{attrs:{"variant":"danger","show":_vm.loadingError ? true : false}},[_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(" "+_vm._s(_vm.loadingError)+" ")])])]),(!_vm.loading && !_vm.loadingError)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-observer',{ref:"busForm"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Bus Name and No.","vid":"name_and_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bus Name and No."}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.nameAndNumber),callback:function ($$v) {_vm.nameAndNumber=$$v},expression:"nameAndNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1648821585)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Receiving Time","vid":"receiving-time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Receiving Time"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"time"},model:{value:(_vm.receivingTime),callback:function ($$v) {_vm.receivingTime=$$v},expression:"receivingTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4094315618)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Destination Address","vid":"destination-address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Destination Address"}},[_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"rows":"1"},model:{value:(_vm.destinationAddress),callback:function ($$v) {_vm.destinationAddress=$$v},expression:"destinationAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2277039387)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Photo","vid":"photo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Photo"}},[_c('b-form-file',{attrs:{"state":errors.length > 0 ? false:null,"accept":"image/*"},model:{value:(_vm.photo),callback:function ($$v) {_vm.photo=$$v},expression:"photo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,706806671)}),(_vm.uploadedPhotoUrl)?_c('div',{staticClass:"d-flex mt-50"},[_c('a',{attrs:{"href":_vm.uploadedPhotoUrl,"target":"_blank"}},[_c('span',[_vm._v("View Photo")])]),_c('div',{staticClass:"ml-1"},[_c('b-form-checkbox',{model:{value:(_vm.removePhoto),callback:function ($$v) {_vm.removePhoto=$$v},expression:"removePhoto"}},[_vm._v(" Remove Photo ")])],1)]):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Favourite","vid":"favourite","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":""}},[_c('b-form-checkbox',{model:{value:(_vm.favourite),callback:function ($$v) {_vm.favourite=$$v},expression:"favourite"}},[_vm._v(" Favourite ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2479966807)})],1)],1),_c('h5',[_vm._v("Conductors")]),_c('b-table-simple',{staticClass:"compact-table",attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Name")]),_c('b-th',[_vm._v("Mobile")]),_c('b-th')],1)],1),_c('b-tbody',_vm._l((_vm.conductors),function(conductor,conductorIndex){return _c('b-tr',{key:conductorIndex},[_c('b-td',[_c('validation-provider',{attrs:{"name":"Name","vid":("conductor_" + conductorIndex + "_name"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(conductor.name),callback:function ($$v) {_vm.$set(conductor, "name", $$v)},expression:"conductor.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-td',[_c('validation-provider',{attrs:{"name":"Mobile","vid":("conductor_" + conductorIndex + "_mobile"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(conductor.mobile),callback:function ($$v) {_vm.$set(conductor, "mobile", $$v)},expression:"conductor.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-td',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({boundary:'window'}),expression:"{boundary:'window'}",modifiers:{"hover":true}}],staticClass:"cursor-pointer text-danger",attrs:{"title":"Delete Conductor","icon":"TrashIcon","size":"20"},on:{"click":function($event){return _vm.deleteConductor(conductorIndex)}}})],1)],1)}),1)],1),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.addCounductor}},[_vm._v(" Add Conductor ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }