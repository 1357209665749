<template>
  <b-modal
    v-model="showModal"
    :title="`${busId ? 'Edit' : 'Add'} Bus`"
    size="lg"
    class="m-4"
    no-close-on-backdrop
    scrollable
    @hidden="$emit('modal-closed')"
    @ok="submit"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-form
      v-if="!loading && !loadingError"
      @submit.prevent="submit"
    >
      <validation-observer
        ref="busForm"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Bus Name and No."
              vid="name_and_number"
              rules="required"
            >
              <b-form-group label="Bus Name and No.">
                <b-form-input
                  v-model="nameAndNumber"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Receiving Time"
              vid="receiving-time"
            >
              <b-form-group label="Receiving Time">
                <b-form-input
                  v-model="receivingTime"
                  :state="errors.length > 0 ? false:null"
                  type="time"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Destination Address"
              vid="destination-address"
            >
              <b-form-group label="Destination Address">
                <b-form-textarea
                  v-model="destinationAddress"
                  :state="errors.length > 0 ? false:null"
                  rows="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Photo"
              vid="photo"
            >
              <b-form-group
                label="Photo"
                class="mb-0"
              >
                <b-form-file
                  v-model="photo"
                  :state="errors.length > 0 ? false:null"
                  accept="image/*"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <div
              v-if="uploadedPhotoUrl"
              class="d-flex mt-50"
            >
              <a
                :href="uploadedPhotoUrl"
                target="_blank"
              >
                <span>View Photo</span>
              </a>

              <div class="ml-1">
                <b-form-checkbox
                  v-model="removePhoto"
                >
                  Remove Photo
                </b-form-checkbox>
              </div>
            </div>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Favourite"
              vid="favourite"
              rules=""
            >
              <b-form-group label="">
                <b-form-checkbox
                  v-model="favourite"
                >
                  Favourite
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <h5>Conductors</h5>

        <b-table-simple
          responsive
          class="compact-table"
        >
          <b-thead>
            <b-tr>
              <b-th>Name</b-th>
              <b-th>Mobile</b-th>
              <b-th />
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(conductor, conductorIndex) in conductors"
              :key="conductorIndex"
            >
              <b-td>
                <validation-provider
                  #default="{ errors }"
                  :name="`Name`"
                  :vid="`conductor_${conductorIndex}_name`"
                  rules=""
                >
                  <b-form-input
                    v-model="conductor.name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-td>
              <b-td>
                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  :vid="`conductor_${conductorIndex}_mobile`"
                  rules=""
                >
                  <b-form-input
                    v-model="conductor.mobile"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-td>
              <b-td>
                <feather-icon
                  v-b-tooltip.hover="{boundary:'window'}"
                  title="Delete Conductor"
                  icon="TrashIcon"
                  size="20"
                  class="cursor-pointer text-danger"
                  @click="deleteConductor(conductorIndex)"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <b-button
          variant="outline-primary"
          @click="addCounductor"
        >
          Add Conductor
        </b-button>
      </validation-observer>
    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving || loading || loadingError ? true : false"
        @click="ok()"
      >
        Submit
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BFormInput, BForm, BSpinner, BAlert,
  BFormTextarea, BFormFile, BFormCheckbox,
  BTableSimple, BThead, BTr, BTh, BTbody, BTd,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
  },
  mixins: [dateMixin],
  props: {
    busId: {
      type: Number,
      required: false,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      showModal: true,
      nameAndNumber: '',
      receivingTime: '',
      destinationAddress: '',
      photo: null,
      removePhoto: false,
      favourite: false,
      conductors: [{
        name: '',
        mobile: '',
      }],
      loading: false,
      loadingError: null,
      saving: false,
      uploadedPhotoUrl: null,
    }
  },
  created() {
    this.initializeForm()
  },
  methods: {
    async initializeForm() {
      this.loading = true

      if (this.busId) {
        try {
          const res = await axios.get(`/delivery/buses/${this.busId}/`)
          const busData = res.data
          this.nameAndNumber = busData.name_and_number
          this.receivingTime = busData.receiving_time
          this.destinationAddress = busData.destination_address
          this.uploadedPhotoUrl = busData.photo
          this.favourite = busData.favourite
          this.conductors = busData.conductors
        } catch (error) {
          this.loadingError = error?.response?.data?.detail || 'Error fetching bus'
          this.loading = false
          return
        }
      }

      this.loading = false
    },
    async submit(event) {
      event.preventDefault()

      const isFormValid = await this.$refs.busForm.validate()
      if (!isFormValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please correct the form errors',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.saving = true

      const formData = new FormData()
      formData.append('name_and_number', this.nameAndNumber)
      formData.append('receiving_time', this.receivingTime || '')
      formData.append('destination_address', this.destinationAddress)

      if (this.photo) {
        formData.append('photo', this.photo)
      }
      formData.append('remove_photo', this.removePhoto)
      formData.append('favourite', this.favourite)

      this.conductors.forEach((conductor, index) => {
        formData.append(`conductors[${index}]name`, conductor.name)
        formData.append(`conductors[${index}]mobile`, conductor.mobile)
      })

      let operation
      let message
      if (this.busId) {
        operation = axios.put(`/delivery/buses/${this.busId}/`, formData)
        message = 'Bus updated successfully'
      } else {
        operation = axios.post('/delivery/buses/', formData)
        message = 'Bus created successfully'
      }

      operation
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$refs.busForm.reset()
          this.saving = false
          this.showModal = false
          this.$emit('saved')
        }).catch(error => {
          this.$refs.busForm.reset()

          const statusCode = error?.response?.status
          const serverErrors = error?.response?.data

          if (serverErrors.conductors) {
            serverErrors.conductors.forEach((conductor, index) => {
              if (conductor.name) {
                serverErrors[`conductor_${index}_name`] = conductor.name
              }

              if (conductor.mobile) {
                serverErrors[`conductor_${index}_mobile`] = conductor.mobile
              }
            })

            delete serverErrors.conductors
          }

          if (statusCode === 400 && serverErrors) {
            this.$refs.busForm.setErrors(serverErrors)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please correct the form errors',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.response?.data?.detail || 'Error saving bus',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          this.saving = false
        })
    },
    addCounductor() {
      this.conductors.push({
        name: '',
        mobile: '',
      })
    },
    deleteConductor(index) {
      this.conductors.splice(index, 1)
    },
  },
}

</script>
